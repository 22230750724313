import { useState } from 'react';

import { AxiosPrivate, AxiosPublic } from '../axios';

export const useGetCommentSet = () => {
  const [isFetching, setFetching] = useState(false);

  const getCommentSetById = async (commentSetId: string) => {
    try {
      const url = `/comment-set/${commentSetId}`;

      setFetching(true);
      const response = await AxiosPrivate.get(url);
      setFetching(false);
      return response;
    } catch (e) {
      setFetching(false);
    }
  };

  const getCommentSetsByParams = async (
    reviewId,
    organizationId,
    authorId,
    withComments,
    withReplies = false,
  ): Promise<any[] | null> => {
    try {
      const params = {
        organizationId,
        authorId,
        withComments,
        withReplies,
      };

      const url = `/comment-set/filter/${reviewId}`;

      setFetching(true);
      const { data } = await AxiosPrivate.get(url, { params });

      return data;
    } catch (e) {
      return null;
    } finally {
      setFetching(false);
    }
  };

  const getCommentSetByParams = async (
    reviewId,
    organizationId,
    authorId,
    withComments,
  ): Promise<any> => {
    const commentSets = await getCommentSetsByParams(
      reviewId,
      organizationId,
      authorId,
      withComments,
    );
    return commentSets ? commentSets[0] : null;
  };

  const getPublicCommentSets = async (reviewId, withComments): Promise<any[] | null> => {
    try {
      const params = {
        withComments,
      };

      const url = `/comment-set/public/${reviewId}`;

      setFetching(true);
      const { data } = await AxiosPublic.get(url, { params });

      return data;
    } catch (e) {
      return null;
    } finally {
      setFetching(false);
    }
  };

  return {
    isFetching,
    getCommentSetById,
    getCommentSetByParams,
    getCommentSetsByParams,
    getPublicCommentSets,
  };
};
