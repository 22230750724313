import { useState } from 'react';
import { AxiosPrivate } from '../axios';

export const useUploadForm = () => {
  const [isFetching, setFetching] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const ATTACHMENT_UPLOAD_URL = '/review-attachments';

  const upload = async (files: any[], reviewId: string) => {
    setFetching(true);
    setUploadErrors([]);

    try {
      const res = await Promise.all(
        files.map(async (file) => {
          const name = file.path.replace(/\s/g, '');
          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', name);
          formData.append('reviewId', reviewId);
          return await AxiosPrivate.post(ATTACHMENT_UPLOAD_URL, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }),
      );

      setUploadedFiles(res.map((r) => r.data));
      setSuccess(true);
    } catch (e) {
      setUploadErrors(e);
      setSuccess(false);
    } finally {
      setFetching(false);
    }
  };

  return {
    isFetching,
    upload,
    uploadErrors,
    success,
    uploadedFiles,
  };
};
