class ResponseErrorType {
  error: string;
  message: string[];

  constructor(errorType: any) {
    this.error = errorType.error || '';
    this.message = errorType.message
      ? Array.isArray(errorType.message)
        ? errorType.message
        : [errorType.message]
      : '';
  }
}

class ResponseErrorDetails {
  class: number;
  code: string;
  lineNumber: number;
  message: string;
  name: string;
  number: number;

  constructor(details: any) {
    this.class = details.class || -1;
    this.code = details.code || '';
    this.lineNumber = details.lineNumber || -1;
    this.message = details.message || '';
    this.name = details.name || '';
    this.number = details.number || -1;
  }

  getMessage(): string {
    return `${this.name} ${this.message}`;
  }
}

class ResponseData {
  errorDetails?: ResponseErrorDetails;
  errorMessage: string;
  errorType: ResponseErrorType;

  constructor(data: any) {
    this.errorDetails = data.errorDetails ? new ResponseErrorDetails(data.errorDetails) : undefined;
    this.errorMessage = data.errorMessage || '';
    this.errorType = new ResponseErrorType(data.errorType);
  }

  getMessages(): string[] {
    if (process.env.NODE_ENV !== 'production' && this.errorDetails) {
      return [...this.errorType.message, this.errorDetails.getMessage()];
    } else {
      return this.errorType.message;
    }
  }

  getErrorType(): string {
    return this.errorType.error;
  }
}

class Response {
  config: any;
  data: ResponseData;

  constructor(response: any) {
    this.config = response.config || {};
    this.data = new ResponseData(response.data);
  }

  getMessages(): string[] {
    return this.data.getMessages();
  }

  getErrorType(): string {
    return this.data.getErrorType();
  }
}

interface IResponseException {
  config: any;
  isAxiosError: boolean;
  request: any;
  response?: Response;

  getErrorMessage(): string[];
}

export default class ResponseException implements IResponseException {
  config: any;
  isAxiosError: boolean;
  request: any;
  response?: Response;
  message: string;

  constructor(exception: any) {
    this.config = exception.config || {};
    this.isAxiosError = exception.isAxiosError;
    this.request = exception.request || {};
    this.message = exception.message || null;
    this.response = exception.response ? new Response(exception.response) : undefined;
  }

  getErrorMessage(): string[] {
    return this.response ? this.response.getMessages() : [this.message];
  }

  getErrorType(): string {
    return this.response ? this.response.getErrorType() : '';
  }
}
