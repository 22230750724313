import { useState } from 'react';
import { AxiosPublic, AxiosPrivate } from '../axios';
import { isLoggedIn } from '../../contexts/auth';

const loggedIn = isLoggedIn();

export const useReviews = () => {
  const [hasReviewError, setError] = useState(false);
  const [isFetchingReviews, setFetching] = useState(false);

  return {
    isFetchingReviews,
    hasReviewError,
    getReview: async (id) => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get(`/reviews/${id}`);
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setFetching(false);
      }
    },
    getReviews: async (filter) => {
      try {
        setFetching(true);
        const response = loggedIn
          ? await AxiosPrivate.get(`/reviews/private/${filter}`)
          : await AxiosPublic.get(`/reviews/${filter}`);
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setFetching(false);
      }
    },
  };
};
