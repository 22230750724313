import { useState, useContext } from 'react';
import { AxiosPrivate } from '../axios';
import { GlobalContext } from '../../contexts/GlobalContext';

export const useGetAllUsers = () => {
  const globalContext = useContext(GlobalContext);
  const [isFetching, setFetching] = useState(false);
  const [allUsers, setAllUsers] = useState<any[]>([]);

  return {
    allUsers,
    isFetching,
    onLoad: async () => {
      try {
        setFetching(true);
        const { data } = await AxiosPrivate.get(`/users?orderBy=firstName&order=ASC`);

        setAllUsers(data);
        setFetching(false);
      } catch (e) {
        globalContext.showMessage({
          title: 'Error',
          body: 'An error has occurred retrieving users',
          variant: 'error',
        });
        setFetching(false);
      }
    },
  };
};
