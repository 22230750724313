import { ReviewStatusEnum } from '@eisc-frontend/shared-ui';

/* eslint-disable no-unused-vars */
export const SystemAdminEmail = {
  name: 'System Administrator',
  email: process.env.SYSTEM_ADMIN_EMAIL || 'eiscregistry@jointsec.nt.ca',
};

export const ROWS_PER_PAGE = 12;

export enum DownloadTypesEnum {
  NONE = 'NONE',
  ALL = 'ALL',
  COMMENTS = 'COMMENTS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export enum CommentStatusEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum CommentTypeEnum {
  Default = 'default',
  Audio = 'audio',
}

export enum ActionType {
  EDIT = 'Edit',
  VIEW = 'View',
}

export const SORT_ORDER = ['DESC', 'ASC'];

export const DownloadFormatOptions = [
  { value: '.pdf', label: 'Adobe PDF' },
  { value: '.docx', label: 'Microsoft Word format' },
  { value: '.xlsx', label: 'Microsoft Excel document' },
];

export const DownloadOptions = [
  { type: DownloadTypesEnum.NONE, label: 'Review Summary Download Options' },
  {
    type: DownloadTypesEnum.ALL,
    label: 'All Comments with Attachments',
    menuOption: true,
    showIfAttachments: true,
  },
  {
    type: DownloadTypesEnum.COMMENTS,
    label: 'Comments only',
    menuOption: true,
  },
  {
    type: DownloadTypesEnum.ATTACHMENTS,
    label: 'Attachments only',
    menuOption: true,
    showIfAttachments: true,
  },
];

export const CommentSizeBase = {
  '.pdf': 0.03,
  '.docx': 0.007,
  '.xlsx': 0.017,
};

export const CommentSizeFactor = {
  '.pdf': 0.0015,
  '.docx': 0.0005,
  '.xlsx': 0.0025,
};

export const Types = [
  { value: 'All', label: 'All' },
  { value: 'Review', label: 'Item for Review' },
  { value: 'IR', label: 'Information Request' },
];

export const PublicStatuses = [
  { value: 'All', label: 'All' },
  { value: 'Submitted,Pre-Screening', label: 'Pre-Screening' },
  { value: 'Awaiting Project Description', label: 'Submission' },
  { value: 'Description Complete,Awaiting Comments,Closed For Comments', label: 'Screening' },
  { value: 'Closed', label: 'Closed' },
];

export const StaffStatuses = [
  { value: 'All', label: 'All' },
  { value: 'Draft', label: 'Draft' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Pre-Screening', label: 'Pre-Screening' },
  { value: 'Awaiting Project Description', label: 'Awaiting Project Description' },
  { value: 'Description Complete', label: 'Description Complete' },
  { value: 'Awaiting Comments', label: 'Awaiting Comments' },
  { value: 'Closed For Comments', label: 'Closed For Comments' },
  { value: 'Closed', label: 'Closed' },
];

export const DateRanges = [
  { value: 'All', label: 'All' },
  { value: '1', label: 'Last 30 Days' },
  { value: '6', label: 'Last 6 months' },
  { value: '12', label: 'Last 12 months' },
];

export const MenuItems = [
  { value: '#staff', label: 'EISC Use' },
  { value: '#overview', label: 'Overview' },
  { value: '#documents', label: 'Documents' },
  { value: '#location', label: 'Location' },
  { value: '#comments', label: 'Comments' },
];

export const EISCUseSectionStatus = [
  ReviewStatusEnum.DescriptionComplete,
  ReviewStatusEnum.AwaitingComments,
  ReviewStatusEnum.ClosedForComments,
];

export const PublicReviewColumns = [
  { id: 'phase', label: 'Phase', isSortable: false },
  {
    id: 'title',
    label: 'Project Name',
    isSortable: false,
    minWidth: 135,
  },
  {
    id: 'developer',
    label: 'Developer',
    isSortable: false,
  },
  {
    id: 'createdAt',
    label: 'Submitted On',
    isSortable: true,
    isDate: true,
  },
  {
    id: 'firstFieldWork',
    label: 'Start Date',
    isSortable: false,
    isDate: true,
  },
  {
    id: 'fileNumber',
    label: 'File No.',
  },
];

export const NonStaffReviewColumns = [
  { id: 'status', label: 'Status', isSortable: false },
  {
    id: 'title',
    label: 'Project Name',
    isSortable: false,
    minWidth: 135,
  },
  {
    id: 'developer',
    label: 'Developer',
    isSortable: false,
  },
  {
    id: 'createdAt',
    label: 'Submitted On',
    isSortable: true,
    isDate: true,
  },
  {
    id: 'firstFieldWork',
    label: 'Start Date',
    isSortable: false,
    isDate: true,
  },
  {
    id: 'fileNumber',
    label: 'File No.',
  },
];

export const StaffReviewColumns = [
  { id: 'status', label: 'Status', isSortable: false },
  {
    id: 'title',
    label: 'Project Name',
    isSortable: false,
    minWidth: 135,
  },
  {
    id: 'developer',
    label: 'Developer',
    isSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Submitted',
    isSortable: true,
    isDate: true,
  },
  {
    id: 'firstFieldWork',
    label: 'Project Schedule',
    isSortable: true,
    isDate: true,
  },
  {
    id: 'fileNumber',
    label: 'File Number',
  },
];
