import useSWR from 'swr';
import { AxiosPublic } from '../axios';
import { CatalogCodeEnum } from '@eisc-frontend/shared-ui';
import { Catalog } from '../../interfaces';

const CATALOG_BASE_URL = 'catalog';
const fetchQuery = (url) => AxiosPublic.get(url).then((res) => res.data);

export const useCatalog = (catalogCode: CatalogCodeEnum) => {
  // Check https://swr.vercel.app/docs/options#options for more SWR options
  const { data, error } = useSWR(`/${CATALOG_BASE_URL}/${catalogCode}`, fetchQuery, {
    revalidateOnFocus: false,
  });
  const catalogData: Catalog[] = data?.map((el) => ({ label: el.name, value: el.id }));

  return {
    data: catalogData,
    isLoading: !error && !data,
  };
};
