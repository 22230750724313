import { useState } from 'react';
import { AxiosPrivate } from '../axios';

export const useReviewDownloads = () => {
  const [isFetching, setFetching] = useState(false);

  const enqueueDownloadAttachments = async (reviewId: string) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.post(`/reviews/download/${reviewId}`);

      return data.referenceId;
    } catch (e) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const downloadAttachments = async (referenceId: string) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.get(`/reviews/download/${referenceId}`);
      return data;
    } catch (e) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  return {
    isFetching,
    enqueueDownloadAttachments,
    downloadAttachments,
  };
};
