import { ROWS_PER_PAGE, SORT_ORDER } from '../../constants';

export const generateQuery = (
  searchFilter = '',
  statusFilter = 'All',
  dateFilter = 'All',
  developerFilter = 'All',
  offset = 0,
  orderBy = 'createdAt',
  order = SORT_ORDER[0],
  boardAbbr = '',
) => {
  let base = `?take=${ROWS_PER_PAGE}&skip=${offset}&orderBy=${
    orderBy || 'createdAt'
  }&order=${order}`; // pagination

  if (searchFilter) {
    base += `&searchFilter="${searchFilter}"`; // search by title, file number or developer
  }

  // filter by status (eg. Avtive, Completed, ...)
  if (statusFilter !== 'All') {
    base += statusFilter;
  }

  if (dateFilter !== 'All') {
    base += `&dateRange=${dateFilter}`; // filter by date range (eg. Last week, Last month, ...)
  }

  if (developerFilter !== 'All') {
    base += `&developerId=${developerFilter}`;
  }

  if (boardAbbr !== '') {
    base += `&boardAbbr=${boardAbbr}`;
  }

  return base;
};
