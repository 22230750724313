import { useState } from 'react';

import { AxiosPrivate } from '../axios';
import ResponseException from '../../models/ResponseException';

export const useUpdateReviewStatus = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [isFetching, setFetching] = useState(false);

  const updateReviewStatus = async (reviewId: string, status: string) => {
    try {
      setFetching(true);
      const response = await AxiosPrivate.patch(`/reviews/${reviewId}/status`, { status });
      setFetching(false);
      setSuccess(true);
      return response;
    } catch (e) {
      setFetching(false);
      setSuccess(false);
      setErrors(new ResponseException(e).getErrorMessage());
    }
  };

  return {
    isFetching,
    updateReviewStatus,
    errors,
    success,
  };
};
