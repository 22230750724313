import { useState } from 'react';

import { AxiosPublic } from '../axios';

export const useOrganizations = () => {
  const [hasOrgError, setError] = useState(false);
  const [isFetchingOrgs, setFetching] = useState(false);

  return {
    isFetchingOrgs,
    hasOrgError,
    getOrganizations: async () => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get('/organizations?orderBy=name&order=ASC');
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
        setError(e);
      }
    },
  };
};
