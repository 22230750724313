import { useState } from 'react';
import { AxiosPrivate } from '../axios';

export const useGetUsers = () => {
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = async (organizationId: string) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.get(`/organizations/${organizationId}/users`);

      setUsers(data);
      setFetching(false);

      return data;
    } catch (e) {
      setFetching(false);
    }
  };

  return {
    isFetching,
    users,
    getUsers,
  };
};
