import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { logout, setUserProfile, restartIdle } from '../contexts/auth';

const sessionThreshold = +(process.env.GATSBY_SESSION_THRESHOLD || 1800000);

axios.defaults.baseURL = process.env.GATSBY_BASE_API_URL || 'http://localhost:4000/api/v1/';

export const AxiosPublic = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export const AxiosPrivate = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

AxiosPrivate.interceptors.request.use(
  async (config) => {
    const orsCookie = Cookies.getJSON('ORS');

    if (!orsCookie) {
      logout();
    }

    const { profile, refreshToken } = orsCookie;
    let accessToken = orsCookie.accessToken;

    const now = new Date().getTime();
    const tokenExpiry = jwt.decode(accessToken).exp * 1000; // both access and refresh has the same exp property
    const tokenTimeLeft = tokenExpiry - now;
    const refreshTokenExpiry = jwt.decode(refreshToken).exp * 1000;
    const refreshTokenTimeLeft = refreshTokenExpiry - now;

    // Session Expired
    if (tokenTimeLeft <= 0 && refreshTokenTimeLeft <= 0) {
      logout();
    }
    // Access Token has expired while refresh token still valid
    else if (tokenTimeLeft <= sessionThreshold && refreshTokenTimeLeft > 0) {
      const refreshResponse = await AxiosPublic.post('/auth/refresh', {
        refreshToken,
      });
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = refreshResponse.data;
      const refreshExpiry = jwt.decode(newRefreshToken).exp;

      Cookies.set(
        'ORS',
        {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          profile,
        },
        {
          expires: new Date(refreshExpiry * 1000),
        },
      );

      accessToken = newAccessToken;
      setUserProfile(newAccessToken, refreshToken, profile, refreshExpiry);
    }

    const authHeader = config.headers.authorization;
    if (!authHeader) {
      config.headers.authorization = `bearer ${accessToken}`;
    }

    restartIdle();
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

AxiosPublic.interceptors.request.use((config) => {
  restartIdle();
  return config;
});
AxiosPublic.interceptors.response.use((response) => response.data);
AxiosPrivate.interceptors.response.use((response) => response.data);
