import { useState } from 'react';

import { AxiosPrivate } from '../axios';
import ResponseException from '../../models/ResponseException';
import ReviewInternalDataModel from '../../models/ReviewInternalDataModel';

export const useUpdateReview = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [isFetching, setFetching] = useState(false);

  const updateReview = async (reviewId: string, data: ReviewInternalDataModel) => {
    try {
      setFetching(true);
      console.log(`Info being sent - ${JSON.stringify(data, null, 2)}`);
      const response = await AxiosPrivate.patch(`/reviews/${reviewId}/internaldata`, data);
      setFetching(false);
      setSuccess(true);
      return response;
    } catch (e) {
      setFetching(false);
      setSuccess(false);
      setErrors(new ResponseException(e).getErrorMessage());
    }
  };

  return {
    isFetching,
    updateReview,
    errors,
    success,
  };
};
