import { useState, useContext } from 'react';
import { AxiosPrivate } from '../axios';
import { GlobalContext } from '../../contexts/GlobalContext';

export const useDistributionList = () => {
  const globalContext = useContext(GlobalContext);
  const [isFetching, setIsFetching] = useState(false);
  const [items, setItems] = useState([]);

  const getAll = async (query) => {
    try {
      setIsFetching(true);

      const { data } = await AxiosPrivate.get('/distribution-list', {
        params: { ...query },
      });

      setItems(data.distributionLists);
    } catch (e) {
      globalContext.showMessage({
        title: 'Error',
        body: 'An error has occurred retrieving distribution lists',
        variant: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    items,
    getAll,
  };
};
