import { useState } from 'react';
import { BoardsQueryParameter } from '@eisc-frontend/shared-ui';
import { AxiosPublic } from '../axios';

const BASE_URL = 'boards';

export const useBoards = () => {
  const [hasBoardError, setError] = useState(false);
  const [isFetchingBoard, setFetchingBoard] = useState(false);
  const [isFetchingBoards, setFetching] = useState(false);

  return {
    isFetchingBoards,
    isFetchingBoard,
    hasBoardError,
    getBoards: async (queryParams?: BoardsQueryParameter) => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get(`/${BASE_URL}`, {
          params: queryParams,
        });
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
        setError(true);
      }
    },
    getBoard: async (abbr: string) => {
      try {
        setFetchingBoard(true);
        const response = await AxiosPublic.get(`/${BASE_URL}/abbr/${abbr}`);
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
        setError(true);
      }
    },
  };
};
